import { Layout, PageHeader } from "@refinedev/antd";

const EmptySider = () => <></>;

const PrivacyPage = () => {
  return (
    <Layout Sider={EmptySider}>
      <PageHeader title="Политика конфиденциальности" ghost={false}>
        <div>
          <p>1. Общие положения </p>

          <p>
            Настоящая политика конфиденциальности обработки персональных данных
            составлена в соответствии с требованиями Закона Республики
            Узбекистан от 02 июля 2019 года №ЗРУ-547 «О персональных данных»
            (далее - Закон о персональных данных) и определяет порядок обработки
            персональных данных и меры по обеспечению безопасности персональных
            данных, предпринимаемые Karimov Azamat Abdusattor O‘g‘li (далее –
            Оператор).
          </p>

          <p>
            1.1. Настоящая политика конфиденциальности Оператора в отношении
            обработки персональных данных (далее – Политика) применяется ко всей
            информации, которую Оператор может получить о посетителях веб-сайта{" "}
            <a href="https://admin.arryt.uz/">https://admin.arryt.uz/</a> и
            использования мобильного приложения Arryt.
          </p>

          <p>
            1.2. Использование Сервиса Оператора Субъектом/Пользователем
            означает безоговорочное согласие Субъекта/Пользователя с настоящей
            Политикой и указанными в ней условиями обработки его персональных
            данных и в случае несогласия с этими условиями Пользователь должен
            воздержаться от использования Сервисов, указанных в настоящей
            Политике.
          </p>

          <p>2. Основные понятия, используемые в Политике</p>

          <p>
            2.1. Автоматизированная обработка персональных данных – обработка
            персональных данных с помощью средств вычислительной техники.
          </p>

          <p>
            2.2. Блокирование персональных данных – временное прекращение
            обработки персональных данных (за исключением случаев, если
            обработка необходима для уточнения персональных данных).
          </p>

          <p>
            2.3. Веб-сайт – совокупность графических и информационных
            материалов, а также программ для электронно-вычислительных машин и
            баз данных, обеспечивающих их доступность в сети интернет по
            сетевому адресу https://admin.arryt.uz/.
          </p>

          <p>
            2.4. Информационная система персональных данных — совокупность
            содержащихся в базах данных персональных данных, и обеспечивающих их
            обработку информационных технологий и технических средств.
          </p>

          <p>
            2.5. Обезличивание персональных данных — действия, в результате
            которых невозможно определить без использования дополнительной
            информации принадлежность персональных данных конкретному
            Пользователю или иному субъекту персональных данных.
          </p>

          <p>
            2.6. Обработка персональных данных – любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств с
            персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных.
          </p>

          <p>
            2.7. Оператор персональных данных – Karimov Azamat Abdusattor O‘g‘li
            обрабатывающий персональные данные Субъекта/Пользователя, в силу
            оказания определенных услуг в соответствии с офертой (заказом)
            Субъекта/Пользователя.
          </p>

          <p>
            2.8. Персональные данные – любая информация, относящаяся прямо или
            косвенно к определенному или определяемому Субъекту/Пользователю
            (далее «Субъект», «Пользователь» или «Субъект/Пользователь»)
            веб-сайта{" "}
            <a href="https://admin.arryt.uz/">https://admin.arryt.uz/</a> и
            мобильного приложения Arryt.
          </p>

          <p>
            2.9. Пользователь – любой посетитель веб-сайта{" "}
            <a href="https://admin.arryt.uz/">https://admin.arryt.uz/</a> и
            мобильного приложения Arryt.
          </p>

          <p>3. Основные права и обязанности Оператора</p>

          <p>3.1. Оператор имеет право:</p>

          <p>
            – получать от субъекта персональных данных достоверные информацию
            и/или документы, содержащие персональные данные;
          </p>

          <p>
            – в случае отзыва субъектом персональных данных согласия на
            обработку персональных данных Оператор вправе продолжить обработку
            персональных данных без согласия субъекта персональных данных при
            наличии оснований, указанных в Законе о персональных данных;
          </p>

          <p>
            – самостоятельно определять состав и перечень мер, необходимых и
            достаточных для обеспечения выполнения обязанностей, предусмотренных
            Законом о персональных данных и принятыми в соответствии с ним
            нормативными правовыми актами, если иное не предусмотрено Законом о
            персональных данных или другим законодательством РУз.
          </p>

          <p>3.2. Оператор обязан:</p>

          <p>
            – предоставлять субъекту персональных данных по его просьбе
            информацию, касающуюся обработки его персональных данных;
          </p>

          <p>
            – организовывать обработку персональных данных в порядке,
            установленном действующим законодательством РУз;
          </p>

          <p>
            – отвечать на обращения и запросы субъектов персональных данных и их
            законных представителей в соответствии с требованиями Закона РУз «О
            персональных данных»;
          </p>

          <p>
            – сообщать в уполномоченный орган по защите прав субъектов
            персональных данных по запросу этого органа необходимую информацию,
            в установленный законодательством срок;
          </p>

          <p>
            – публиковать или иным образом обеспечивать неограниченный доступ к
            настоящей Политике в отношении обработки персональных данных;
          </p>

          <p>
            – принимать правовые, организационные и технические меры для защиты
            персональных данных от неправомерного или случайного доступа к ним,
            уничтожения, изменения, блокирования, копирования, предоставления,
            распространения персональных данных, а также от иных неправомерных
            действий в отношении персональных данных;
          </p>

          <p>
            – прекратить передачу (распространение, предоставление, доступ)
            персональных данных, прекратить обработку и уничтожить персональные
            данные в порядке и случаях, предусмотренных Законом РУз «О
            персональных данных»;
          </p>

          <p>
            – исполнять иные обязанности, предусмотренные Законом РУз «О
            персональных данных».
          </p>

          <p>4. Основные права и обязанности субъектов персональных данных</p>

          <p>4.1. Субъекты персональных данных имеют право:</p>

          <p>
            - знать о наличии у собственника и (или) оператора, а также третьего
            лица своих персональных данных и их состав;
          </p>

          <p>
            - получать по запросу информацию об обработке персональных данных от
            собственника и (или) оператора;
          </p>

          <p>
            - получать информацию об условиях предоставления доступа к своим
            персональным данным от собственника и (или) оператора;
          </p>

          <p>
            - обращаться по вопросам защиты прав и законных интересов в
            отношении своих персональных данных в уполномоченный государственный
            орган или суд;
          </p>

          <p>
            - дать согласие на обработку своих персональных данных и отозвать
            такое согласие, кроме случаев, предусмотренных настоящим Законом;
          </p>

          <p>
            - дать согласие собственнику и (или) оператору, а также третьему
            лицу на распространение своих персональных данных в общедоступных
            источниках персональных данных;
          </p>

          <p>
            - требовать от собственника и (или) оператора временного
            приостановления обработки своих персональных данных, в случае, если
            персональные данные являются неполными, устаревшими, неточными,
            незаконно полученными или не являются необходимыми для целей
            обработки.
          </p>

          <p>4.2. Субъекты персональных данных обязаны:</p>

          <p>– предоставлять Оператору достоверные данные о себе;</p>

          <p>
            – сообщать Оператору об уточнении (обновлении, изменении) своих
            персональных данных.
          </p>

          <p>
            - предоставление своих персональных данных в целях защиты основ
            конституционного строя Республики Узбекистан, нравственности,
            здоровья, прав и законных интересов граждан Республики Узбекистан,
            обеспечения обороны страны и безопасности государства.
          </p>

          <p>
            4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо
            сведения о другом субъекте персональных данных без согласия
            последнего, несут ответственность в соответствии с законодательством
            РУз.
          </p>

          <p>
            5. Оператор может обрабатывать следующие персональные данные
            Пользователя
          </p>

          <p>5.1.&nbsp;Фамилия, имя, отчество.</p>

          <p>5.2.&nbsp;Электронный адрес.</p>

          <p>5.3.&nbsp;Номера телефонов.</p>

          <p>5.4. Местоположение.</p>

          <p>
            5.5. Также в мобильном приложении Arryt и на сайте{" "}
            <a href="https://admin.arryt.uz/">https://admin.arryt.uz/</a>{" "}
            происходит сбор и обработка обезличенных данных о посетителях.
          </p>

          <p>
            5.5. Вышеперечисленные данные далее по тексту Политики объединены
            общим понятием Персональные данные.
          </p>

          <p>6. Принципы обработки персональных данных</p>

          <p>
            6.1. Обработка персональных данных осуществляется на законной и
            справедливой основе.
          </p>

          <p>
            6.2. Обработка персональных данных ограничивается достижением
            конкретных, заранее определенных и законных целей. Не допускается
            обработка персональных данных, несовместимая с целями сбора
            персональных данных.
          </p>

          <p>
            6.3. Не допускается объединение баз данных, содержащих персональные
            данные, обработка которых осуществляется в целях, несовместимых
            между собой.
          </p>

          <p>
            6.4. Обработке подлежат только персональные данные, которые отвечают
            целям их обработки.
          </p>

          <p>
            6.5. Содержание и объем обрабатываемых персональных данных
            соответствуют заявленным целям обработки. Не допускается
            избыточность обрабатываемых персональных данных по отношению к
            заявленным целям их обработки.
          </p>

          <p>
            6.6. При обработке персональных данных обеспечивается точность
            персональных данных, их достаточность, а в необходимых случаях и
            актуальность по отношению к целям обработки персональных данных.
            Оператор принимает необходимые меры и/или обеспечивает их принятие
            по удалению или уточнению неполных или неточных данных.
          </p>

          <p>
            6.7. Хранение персональных данных осуществляется в форме,
            позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен законодательством РУз, договором,
            стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных. Обрабатываемые персональные
            данные уничтожаются либо обезличиваются по достижении целей
            обработки или в случае утраты необходимости в достижении этих целей,
            если иное не предусмотрено законодательством РУз.
          </p>

          <p>7. Цели обработки персональных данных</p>

          <p>7.1. Цель обработки персональных данных Пользователя:</p>

          <p>
            7.1.1. В целях предоставления Сервиса и оказания услуг Оператором и
            исполнения (акцептования) оферты Субъекта/Пользователя Оператор
            использует персональные данные Субъекта/Пользователя в следующих
            целях:
          </p>

          <p>- идентификация Субъекта/Пользователя в рамках оферты;</p>

          <p>- предоставление Пользователю персонализированных Сервисов;</p>

          <p>
            - связь с Пользователем, в том числе направление уведомлений,
            запросов и информации, касающихся использования Сервиса, оказания
            услуг, а также обработка запросов и заявок от Пользователя;
          </p>

          <p>
            - улучшение качества Сервиса, удобства их использования, разработка
            новых Сервисов и услуг;
          </p>

          <p>- рекламы своих продуктов и услуг;</p>

          <p>
            - проведение статистических и иных исследований на основе
            предоставленных данных.
          </p>

          <p>
            7.2. Оператор имеет право направлять Пользователю уведомления о
            новых продуктах и услугах, специальных предложениях и различныхArry
            событиях. При этом, Пользователь всегда может отказаться от
            получения информационных сообщений, направив Оператору письмо на
            адрес электронной почты&nbsp;info@arryt.uz с пометкой «Отказ от
            уведомлений о новых продуктах и услугах и специальных предложениях».
          </p>

          <p>
            7.3. Обезличенные данные Пользователей, собираемые с помощью
            сервисов интернет-статистики, служат для сбора информации о
            действиях Пользователей на сайте{" "}
            <a href="https://admin.arryt.uz/">https://admin.arryt.uz/</a> и
            мобильного приложения Arryt, улучшения качества сайта{" "}
            <a href="https://admin.arryt.uz/">https://admin.arryt.uz/</a> и его
            содержания и мобильного приложения Arryt.
          </p>

          <p>8. Условия обработки персональных данных</p>

          <p>
            8.1. Обработка персональных данных осуществляется с согласия
            субъекта персональных данных на обработку его персональных данных.
          </p>

          <p>
            8.2. Обработка персональных данных необходима для осуществления
            возложенных задач Законом РУз «О Персональных данных».
          </p>

          <p>
            9. Порядок сбора, хранения, передачи и других видов обработки
            персональных данных
          </p>

          <p>
            Безопасность персональных данных, которые обрабатываются Оператором,
            обеспечивается путем реализации правовых, организационных и
            технических мер, необходимых для выполнения в полном объеме
            требований действующего законодательства РУз в области защиты
            персональных данных.
          </p>

          <p>
            9.1. Оператор обеспечивает сохранность персональных данных и
            принимает все возможные меры, исключающие доступ к персональным
            данным неуполномоченных лиц.
          </p>

          <p>
            9.2. Персональные данные Пользователя никогда, ни при каких условиях
            не будут переданы третьим лицам, за исключением случаев, связанных с
            исполнением действующего законодательства РУз, либо в случае, если
            субъектом персональных данных дано согласие Оператору на передачу
            данных третьему лицу для исполнения обязательств по
            гражданско-правовому договору.
          </p>

          <p>
            9.3. В случае выявления неточностей в персональных данных,
            Пользователь может актуализировать их самостоятельно, путем
            направления Оператору уведомление на адрес электронной почты
            Оператора info@arryt.uz с пометкой «Актуализация персональных
            данных».
          </p>

          <p>
            9.4. Срок обработки персональных данных определяется достижением
            целей, для которых были собраны персональные данные, если иной срок
            не предусмотрен договором или действующим законодательством.
            <br />
            Пользователь может в любой момент отозвать свое согласие на
            обработку персональных данных, направив Оператору уведомление
            посредством электронной почты на электронный адрес
            Оператора&nbsp;info@arryt.uz&nbsp;с пометкой «Отзыв согласия на
            обработку персональных данных».
          </p>

          <p>
            9.5. Вся информация, которая собирается сторонними сервисами, в том
            числе платежными системами, средствами связи и другими поставщиками
            услуг, хранится и обрабатывается указанными лицами (Операторами) в
            соответствии с их Пользовательским соглашением и Политикой
            конфиденциальности. Субъект персональных данных и/или Пользователь
            обязан самостоятельно своевременно ознакомиться с указанными
            документами. Оператор не несет ответственность за действия третьих
            лиц, в том числе указанных в настоящем пункте поставщиков услуг.
          </p>

          <p>
            9.6. Установленные субъектом персональных данных запреты на передачу
            (кроме предоставления доступа), а также на обработку или условия
            обработки (кроме получения доступа) персональных данных, разрешенных
            для распространения, не действуют в случаях обработки персональных
            данных в государственных, общественных и иных публичных интересах,
            определенных законодательством РУз.
          </p>

          <p>
            9.7. Оператор при обработке персональных данных обеспечивает
            конфиденциальность персональных данных.
          </p>

          <p>
            9.8. Оператор осуществляет хранение персональных данных в форме,
            позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен законодательством РУз, договором,
            стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных.
          </p>

          <p>
            9.9. Условием прекращения обработки персональных данных может
            являться достижение целей обработки персональных данных, истечение
            срока действия согласия субъекта персональных данных или отзыв
            согласия субъектом персональных данных, а также выявление
            неправомерной обработки персональных данных.
          </p>

          <p>
            10. Перечень действий, производимых Оператором с полученными
            персональными данными
          </p>

          <p>
            10.1. Оператор осуществляет сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление и уничтожение персональных
            данных.
          </p>

          <p>
            10.2. Оператор осуществляет автоматизированную обработку
            персональных данных с получением и/или передачей полученной
            информации по информационно-телекоммуникационным сетям или без
            таковой.
          </p>

          <p>11. Конфиденциальность персональных данных</p>

          <p>
            Оператор и иные лица, получившие доступ к персональным данным,
            обязаны не раскрывать третьим лицам и не распространять персональные
            данные без согласия субъекта персональных данных, если иное не
            предусмотрено законодательством РУз.
          </p>

          <p>12. Заключительные положения</p>

          <p>
            12.1. Пользователь может получить любые разъяснения по интересующим
            вопросам, касающимся обработки его персональных данных, обратившись
            к Оператору с помощью электронной почты info@arryt.uz.
          </p>

          <p>
            12.2. В данном документе будут отражены любые изменения политики
            обработки персональных данных Оператором. Политика действует
            бессрочно до замены ее новой версией.
          </p>

          <p>
            12.3. Актуальная версия Политики в свободном доступе расположена в
            сети Интернет по адресу&nbsp;
            <a href="https://admin.arryt.uz/">https://admin.arryt.uz/</a>
          </p>
        </div>
      </PageHeader>
    </Layout>
  );
};

export default PrivacyPage;
